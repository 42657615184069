import { getFrameworkVars, getMicroAppVars } from "@sparky/framework";
import { SPARKY_NAV3_ROUTES_EXTPOINT } from "@sparky/framework/extpoints";
import { hasAIAccess, hasNetsecUI } from "src/utils/navAvailUtils";
import {
    HomeIcon
} from "@panwds/icons";
import type { ExtensionsConfig, SparkyNavRouteConfig } from "@sparky/framework/extpoints";

const netsecUIRoutes = [
    {
        key: "sase-home",
        title: "Command Center",
        path: "/aiops-free/home",
        menuLink: "HOME",
        custom: "NetsecUIWrapper",
        icon: HomeIcon
    },
    {
        key: "sase-home",
        title: "Command Center",
        path: "/aiops-free/home/summary",
        menuLink: "HOME",
        custom: "NetsecUIWrapper",
        icon: HomeIcon,
        hidden: true
    }, {
        key: "home-threats",
        title: "Command Center - Threats",
        path: "/aiops-free/home/threats",
        menuLink: "HOME",
        custom: "NetsecUIWrapper",
        hidden: true
    }, {
        key: "home-operational_health",
        title: "Command Center - Operational Health",
        path: "/aiops-free/home/operational_health",
        menuLink: "HOME",
        custom: "NetsecUIWrapper",
        hidden: true
    }, {
        key: "home-data_security",
        title: "Command Center - Data Security",
        path: "/aiops-free/home/data_security",
        menuLink: "HOME",
        custom: "NetsecUIWrapper",
        hidden: true
    }
]

const generateChildRoutesWithProps = (routes) => (
    routes.map((route) => ({
        ...route,
        mapStateToProps: (props) => ({
            ...props,
            routes: [route],
            vars: getMicroAppVars(),
            frameworkVars: getFrameworkVars()
        }),
    }))
);

export default {
    point: SPARKY_NAV3_ROUTES_EXTPOINT,
    configs: [
        {
            id: "netsec.cc.routes.security.insights.ai.access",
            config: {
                path: "/insights/security/ai-access",
                replacePaths: "/insights/ai-access/*",
                stateLoaders: ["auth"],
                contentClassName: "panwds-tw3",
                component: async () => await import("../ai-access/AiAccess"),
                isAllowed: hasAIAccess
            }
        },
        {
            id: "netsec.cc.routes.security.insights.ai.access.use.case",
            config: {
                path: "/insights/security/ai-access/use-case/*",
                replacePaths: "/insights/ai-access/use-case/*",
                stateLoaders: ["auth"],
                contentClassName: "panwds-tw3",
                component: async () => await import("../ai-access/AiAccess"),
                isAllowed: hasAIAccess
            }
        },
        {
            id: "netsec.cc.routes.command.center",
            config: {
                path: "/home",
                stateLoaders: ["auth"],
                contentClassName: "panwds-tw3",
                component: async () => await import("../AppViewer"),
                mapStateToProps: (props) => ({
                    ...props,
                    routes: [{
                        key: "sase-home",
                        title: "Command Center",
                        icon: "menu-sase-home",
                        path: "/home",
                        noRBACheck: true,
                        menuLink: "HOME",
                        custom: "NetsecUI",
                    }],
                    vars: getMicroAppVars(),
                    frameworkVars: getFrameworkVars(),
                }),
                isAllowed: hasNetsecUI
            }
        },
        {
            id: "netsec.cc.routes.aiops.free.command.center",
            config: {
                path: "/aiops-free/home",
                stateLoaders: ["auth"],
                contentClassName: "panwds-tw3",
                component: async () => await import("../AppViewer"),
                mapStateToProps: (props) => ({
                    ...props,
                    routes: generateChildRoutesWithProps(netsecUIRoutes),
                    vars: getMicroAppVars(),
                    frameworkVars: getFrameworkVars(),
                }),
                isAllowed: hasNetsecUI
            }
        }
    ]
} as ExtensionsConfig<SparkyNavRouteConfig>;
